import React from 'react'
import './banner.css'
// import heroVideo from '../assets/'
import { NavLink } from 'react-router-dom'
const banner = ({id}) => {
  return (
    <div id={id} className='banner-container name="media"'> 
     <video muted loop autoPlay>
    <source src="https://www.thekabadiwala.com/images/landing/hero-video-mp4.mp4" type="video/mp4"/>
     Your browser does not support the video tag.
    </video>

    <div className="banner-content">
        <h1>Got scrap? Sell it to us.</h1>
        <p>Sell us your recyclable wastes and help contribute to the circular economy.</p>
        <div className="download-links" style={{justifyContent: 'center', flexWrap: 'wrap'}}>
                <NavLink to='https://play.google.com/store/apps/details?id=com.scrapcart.app&hl=en'>
                <img src="https://freelogopng.com/images/all_img/1664285914google-play-logo-png.png" alt="Google play store" />
                Download on play Store
                </NavLink>
                
                <NavLink to='https://apps.apple.com/us/app/'>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Mac_App_Store_logo.png/800px-Mac_App_Store_logo.png"  alt="App Store" />
                Download on App Store
                </NavLink>
            </div>

    </div>
    </div>
  )
}

export default banner
