import React from 'react'
import './DownloadOur.css'
// import Mockup from '../assets/mockup.png'
import Mockup from '../assets/mobbileApp.png'
import downloadImg from "../assets/downloadIMG2.png"
import MobdownloadImg from "../assets/MobdownloadIMG2.png"
import { NavLink } from 'react-router-dom'
const DownloadOurApp = ({id}) => {
  return (
    <div className='download-container' id={id}>

        <div className='download-app'>
          {/* <img src="https://www.thekabadiwala.com/images/individual/individual-mobile-screen-hero.png" alt="" /> */}
          <img src={downloadImg} alt="" />
        </div>


        <div className='donwload-content'>
            <h1 className=''>Download Our App From The Store</h1>
            <p>Download our app to easily manage and sell your scrap. Quick, simple, and efficient—get started today!</p>

            <div className="download-links">

            <NavLink to='https://play.google.com/store/apps/details?id=com.scrapcart.app&hl=en'>
                <img src="https://freelogopng.com/images/all_img/1664285914google-play-logo-png.png" alt="Google play store" />
                Play Store
                </NavLink>
                
                <NavLink to='https://apps.apple.com/us/app/'>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Mac_App_Store_logo.png/800px-Mac_App_Store_logo.png"  alt="App Store" />
                App Store
                </NavLink>
            </div>
        </div>

        {/* mobbile side view */}
        <div className='mobbile-side'>
            {/* <img src="https://www.thekabadiwala.com/images/individual/individual-mobile-screen-hero-mobile.webp" alt="" /> */}
            <img src={MobdownloadImg} alt="" />
        </div>
    </div>
  )
}

export default DownloadOurApp