import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig"; // Import collection and getDocs from FirebaseConfig
import { getDocs, collection } from "firebase/firestore";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const blogCollectionRef = collection(db, "blogs");
      const blogSnapshot = await getDocs(blogCollectionRef);
      const blogData = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogData);
    };

    fetchData();
  }, []);

  return (
    <div className="cardContainer">
      {blogs.map((blog) => (
        <div key={blog.id} className="card-row">
          {blog.imageUrl && 
          <img className="blogImage" src={blog.imageUrl} alt="Blog" />}
          <p className="date">{blog.createdAt && blog.createdAt.toDate().toLocaleDateString()}</p>
          <h2>{blog.title}</h2>
          <p className="aboutBlog">{blog.description}</p>
          {/* Pass the document ID as a parameter in the URL */}
          <Link to={`/BlogContent/${blog.id}`}>
            <button className="readMore-btn">Read More</button>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default Blog;
