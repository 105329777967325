import "../components/Blog.css";
import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig"; // Import Firestore database instance
import { getDocs, collection, query, orderBy, limit } from "firebase/firestore";
import './MarketReports.css'

const MarketReports = () => {
  const [latestReport, setLatestReport] = useState([]);
  const [language, setLanguage] = useState("english"); // Set the default language
  const [isUrdu, setIsUrdu] = useState(false);
  const [isFirstUrdu, setIsFirstUrdu] = useState(false); // Track if the first content is in Urdu
  const [createdAtTimestamp, setCreatedAtTimestamp] = useState()

  useEffect(() => {
    const fetchLatestReport = async () => {
      const reportsCollectionRef = collection(db, "marketReports");
      const reportsQuery = query(reportsCollectionRef, orderBy("createdAt", "desc"), limit(1));
      const reportsSnapshot = await getDocs(reportsQuery);
  
      if (!reportsSnapshot.empty) {
        const latestReportData = reportsSnapshot.docs[0].data();
        const createdAtTimestamp = latestReportData.createdAt;
        const sortedReport = [];
        
        const numberOfEntries = Object.keys(latestReportData).length / 2;
  
        for (let i = 1; i <= numberOfEntries; i++) {
          sortedReport.push({
            title: latestReportData[`title${i}`],
            description: latestReportData[`description${i}`]
          });
        }
  
        setLatestReport(sortedReport);
        setIsUrdu(isUrduContent(latestReportData));
        setIsFirstUrdu(isUrduContent(latestReportData[`title1`]));
  
        // Set the createdAt timestamp in state
        setCreatedAtTimestamp(createdAtTimestamp);
      }
    };
  
    fetchLatestReport();
  }, []);

  // Function to check if content is in Urdu
  const isUrduContent = (text) => {
    return /[آ-ی]/.test(text);
  };

  return (
    <div className={`marketReports-content ${isUrdu ? 'urdu-content' : ''}`}>
      {latestReport.length > 0 && (
        <div className='content-box'>
          <div className={`date-container ${isFirstUrdu ? 'urdu-date' : ''}`}>
            <p>{createdAtTimestamp ? new Date(createdAtTimestamp.seconds * 1000).toLocaleString() : ''}</p>
            </div>

          {latestReport.map((report, index) => (
            <div key={index} className={`report-container ${isUrduContent(report.title) ? 'urdu-report' : ''}`}>
              <div className={`title-container ${isUrduContent(report.title) ? 'urdu-text' : ''}`}>
                <h2 className="title">{report.title}</h2>
              </div>
              <div className={`description-container ${isUrduContent(report.title) ? 'urdu-text' : ''}`}>
                <p>{report.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MarketReports;
