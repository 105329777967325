// // import React from 'react'
// // import { FcMoneyTransfer } from "react-icons/fc";
// // import { RiVerifiedBadgeFill } from "react-icons/ri";
// // import { FaWeight } from "react-icons/fa";
// // import { MdOutlineWheelchairPickup } from "react-icons/md";


// // const WhyScrap = ({id}) => {
// //   return (
// //     <div id={id} className='section-container whyScrap'>

// //       {/* <div className='whyScrap-heading'>
// //         <hr className='border'/>
// //         <span>Why ScrapCart</span>
// //         <hr className='border'/>
// //         </div> */}

// //         <h1 className='section-title'>Why Scrap Cart</h1>

// //         <div className="cart-row">
// //             <div className="whyScrapCarts">
// //                 <div className="whyScrapIcon">
// //                 <FcMoneyTransfer color='white'/>
// //                 </div>
// //                 <h2>Best rates</h2>
// //                 <p className='whyScrapText'>ScrapCart give you best prices for your scrap.</p>
// //             </div>

// //             <div className="whyScrapCarts">
// //                 <div className="whyScrapIcon">
// //                 <RiVerifiedBadgeFill color='white'/>
// //                 </div>
// //                 <h2>Verified Scrap Pickup Team</h2>
// //                 <p className='whyScrapText'>Our verified scrap team come to your doorstep for pickup.</p>
// //             </div>

// //             <div className="whyScrapCarts">
// //                 <div className="whyScrapIcon">
// //                 <FaWeight color='white'/>
// //                 </div>
// //                 <h2>Digital Weighing scale</h2>
// //                 <p className='whyScrapText'>We use digital weighing scale ISO Certified.</p>
// //             </div>

// //             <div className="whyScrapCarts">
// //                 <div className="whyScrapIcon">
// //                 <MdOutlineWheelchairPickup color='white'/>
// //                 </div>
// //                 <h2>Hassle free Pickup</h2>
// //                 <p className='whyScrapText'>ScrapCart provide you hassle free doorstep scrap pickup service.</p>
// //             </div>

// //         </div>

// //     </div>
// //   )
// // }

// // export default WhyScrap




// import React from 'react'
// import { FcMoneyTransfer } from "react-icons/fc";
// import { RiVerifiedBadgeFill } from "react-icons/ri";
// import { FaWeight } from "react-icons/fa";
// import { MdOutlineWheelchairPickup } from "react-icons/md";
// import Image1 from "../assets/1.png"
// import Image2 from "../assets/2.png"
// import Image3 from "../assets/3.png"
// import Image4 from "../assets/4.png"
// import './whyScrap.css'


// const WhyScrap = ({id}) => {
//   return (
//     // <div id={id} className='whyScrap-container'>
//     //     <h1 className='section-title'>How We Work</h1>
//     //     <p className='scrap-para'>Scrap Cart can Help you to save on your home-related finances</p>

//     //     <div className="cart-row">
//     //         <div className="whyScrapCarts">
//     //           <div className="round-box"></div>
//     //             {/* <div className="whyScrapIcon">
//     //             <FcMoneyTransfer color='white'/>
//     //             </div> */}
//     //             <img src={Image1} alt="" />
//     //             <h2>Sign up for free in 12 minutes</h2>
//     //             <p className='whyScrapText'>Speak to a mortgage advisor</p>
//     //         </div>

//     //         <div className="whyScrapCarts">
//     //             {/* <div className="whyScrapIcon">
//     //             <RiVerifiedBadgeFill color='white'/>
//     //             </div> */}
//     //           <div className="round-box"></div>
//     //             <img src={Image2} alt="" />
//     //             <h2>Get personalised deals</h2>
//     //             <p className='whyScrapText'>Our verified scrap team come to your doorstep for pickup.</p>
//     //         </div>

//     //         <div className="whyScrapCarts">
//     //             {/* <div className="whyScrapIcon">
//     //             <FaWeight color='white'/>
//     //             </div> */}
//     //           <div className="round-box"></div>
//     //             <img src={Image3} alt="" />
//     //             <h2>We Keep looking for better deals</h2>
//     //             <p className='whyScrapText'>We use digital weighing scale ISO Certified.</p>
//     //         </div>

//     //         <div className="whyScrapCarts">
//     //             {/* <div className="whyScrapIcon">
//     //             <MdOutlineWheelchairPickup color='white'/>
//     //             </div> */}
//     //           <div className="round-box"></div>
//     //             <img src={Image4} alt="" />
//     //             <h2>Speak to a mortgage advisor</h2>
//     //             <p className='whyScrapText'>ScrapCart provide you hassle free doorstep scrap pickup service.</p>
//     //         </div>

//     //     </div>

//     // </div>
    
//     <div className="zero-waste-content">

//      <div className="text">
//     <h1>After Collection</h1>
//     <p>With our zero waste management services we help your society to turn zero waste by incorporating zero waste practices within its residents & stakeholders.</p>
//     </div>

//     <div className="solu-services-box">
//                 <div className="service">
//                     {/* <img width="130px" src={Image1} alt="process logo" /> */}
//                     <div className="service-heading">
//                         <h3>Why</h3>
//                         <div className='count'>1</div>
//                     </div>
//                     <p>To promote the circular flow of materials to reduce the need for landfill space.</p>
//                 </div>

//                 <div className="service">
//                     {/* <img width="130px" src={Image2} alt="process logo" /> */}
//                     <div className="service-heading">
//                         <h3>Benefits</h3>
//                         <div className='count'>2</div>
//                     </div>
//                     <p>Establishing circular economy benefits in reducing climate impact, conserving resources and minimizing pollution.</p>
//                 </div>


//                 <div className="service">
//                     {/* <img width="130px" src={Image3} alt="process logo" /> */}
//                     <div className="service-heading">
//                         <h3>Our Solution</h3>
//                         <div className='count'>3</div>
//                     </div>
//                     <p>Our services help you prevent wasteful practices by reducing, reusing, and recycling waste to become a zero waste society.</p>
//                 </div>

//     </div>
//     </div> 
//   )
// }

// export default WhyScrap

import React from 'react'
import Image1 from "../assets/1.png"
import Image2 from "../assets/2.png"
import Image3 from "../assets/3.png"
import Image4 from "../assets/4.png"
import './whyScrap.css'

const WhyScrap = ({id}) => {
  return (
    <div className='we-work-container' id={id}>
       <h1 className='section-title'>Our Process</h1>
       
       <div className="solu-services-box">
    <div className="service">
        <img width="130px" src={Image1} alt="process logo" />
        <div className="service-heading">
            <h3>Easy Registration</h3>
        </div>
        <p>Sign up quickly using your mobile number or social media account, verify via OTP, and start using ScrapCart right away.</p>
    </div>

    <div className="service">
        <img width="130px" src={Image2} alt="process logo" />
        <div className="service-heading">
            <h3>Schedule Doorstep Pickup</h3>
        </div>
        <p>Choose the type of scrap, schedule a convenient pickup, and our verified vendors will come to your location, pay you instantly.</p>
    </div>

    <div className="service">
        <img width="130px" src={Image3} alt="process logo" />
        <div className="service-heading">
            <h3>Post Ads for Top Bids</h3>
        </div>
        <p>List your scrap for sale, receive top bids from vendors, accept the best offer, and get your scrap collected and paid easily.</p>
    </div>
</div>
    </div>
  )
}

export default WhyScrap