// import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
// import "./Header.css";
// // import Logo from "../assets/main-logo.png";
// // import Logo from "../assets/scrap-logo.png";
// import Logo from "../assets/scrapCart-mainLogo.png";
// import { IoCloseSharp } from "react-icons/io5";
// import { FaBars } from "react-icons/fa6";

// const Header = () => {
//   const navItems = [
//     { id: 1, text: "Home", url: "#sell-scrap" },
//     { id: 2, text: "What We Work", url: "#why-scrapCart" },
//     { id: 4, text: "After Collection", url: "#after-collection" },
//     { id: 5, text: "Reviews", url: "#reviews" },
//     // { id: 6, text: "Contact Us", url: "#contact-us" },
//   ];

//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [activeLink, setActiveLink] = useState("#sellScrap");

//   useEffect(() => {
//     const handleScroll = () => {
//       if (isMenuOpen) {
//         setIsMenuOpen(false);
//       }
//     };

//     if (isMenuOpen) {
//       window.addEventListener("scroll", handleScroll);
//     }

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [isMenuOpen]);

//   const handleLinkClick = (url) => {
//     setActiveLink(url);
//     if (isMenuOpen) {
//       setIsMenuOpen(false);
//     }
//   };

//   return (
//     <div className="main-header">
//       <header>
//         <div className="logo-div">
//           <NavLink to="/">
//             <img
//               src={Logo}
//               style={{ objectFit: "cover" }}
//               width='180px'
//               alt="logo"
//             />
//           </NavLink>
//         </div>



//         {/* menu bar */}
//         <div className="menu-bar">
//           <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
//             {isMenuOpen ? <IoCloseSharp /> : <FaBars />}
//           </button>
//         </div>

//         <div
//           className={
//             isMenuOpen ? "mobbile-links open" : "mobbile-links closed Nav-list"
//           }
//         >
//           <ul>
//           {navItems.map((item) => (
//             <li key={item.id}>
//               <HashLink
//                 smooth
//                 to={item.url}
//                 onClick={() => handleLinkClick(item.url)}
//                 className={activeLink === item.url ? "custom-active" : ""}
//               >
//                 {item.text}
//               </HashLink>
//             </li>
//           ))}
//           </ul>
//         <div>
//           <button>
//             <NavLink>
//             Download Now
//             </NavLink>
//             </button>
//           </div>
//         </div>


//       </header>
//     </div>
//   );
// };

// export default Header;

import React, { useState, useEffect } from "react";
import { NavLink,Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Header.css";
import Logo from "../assets/scrapCart-mainLogo.png";
import { IoCloseSharp } from "react-icons/io5";
import DownloadPlayStore from "../assets/playStore.png"
import DownlaodAppStore from "../assets/appStore.png"
import { FaBars } from "react-icons/fa6";

const Header = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isModalOpen) {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  const navItems = [
    { id: 1, text: "Home", url: "#banner" },
    // { id: 1, text: "Home", url: "#sell-scrap" },
    { id: 2, text: "What We Work", url: "#why-scrapCart" },
    { id: 4, text: "After Collection", url: "#after-collection" },
    { id: 5, text: "Reviews", url: "#reviews" },
    // { id: 6, text: "Contact Us", url: "#contact-us" },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("#sell-scrap");
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!isScrolling) {
        const sections = navItems.map(item => document.querySelector(item.url));
        const scrollPosition = window.scrollY + window.innerHeight / 2; // Adjusted to half the viewport height

        let currentSection = "#sell-scrap";
        for (let i = 0; i < sections.length; i++) {
          if (sections[i].offsetTop <= scrollPosition) {
            currentSection = navItems[i].url;
          }
        }

        // Handle last section case
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          currentSection = navItems[navItems.length - 1].url;
        }

        setActiveLink(currentSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navItems, isScrolling]);

  const handleLinkClick = (url) => {
    setActiveLink(url);
    setIsScrolling(true);
    setTimeout(() => setIsScrolling(false), 200); // Prevent scroll listener override for 0.2 seconds
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <div className="main-header">
      <header>
        <div className="logo-div">
          <Link to="">
            <img
              src={Logo}
              style={{ objectFit: "cover" }}
              alt="logo"
            />
          </Link>
        </div>

        {/* menu bar */}
        <div className="menu-bar">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <IoCloseSharp /> : <FaBars />}
          </button>
        </div>

        <div
          className={
            isMenuOpen ? "mobbile-links open" : "mobbile-links closed Nav-list"
          }
        >
          <ul>
            {navItems.map((item) => (
              <li key={item.id}>
                <HashLink
                  smooth
                  to={item.url}
                  onClick={() => handleLinkClick(item.url)}
                  className={activeLink === item.url ? "custom-active" : ""}
                >
                  {item.text}
                </HashLink>
              </li>
            ))}
          </ul>
          <div>
            <button>
              <Link onClick={openModal}>
                Download Now
              </Link>
            </button>
          </div>
        </div>

        {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>{" "}
            {/* Close icon */}
            <div className="modal-content">

              <Link className='downlaod-link'
                to="https://play.google.com/store/apps/details?id=com.scrapcart.app&hl=en"
                target="_blank"
              >
                <img src={DownloadPlayStore} alt="Downlaod PlayStore " />
              </Link>

              <Link className='downlaod-link'
                to="https://apps.apple.com/us/app"
                target="_blank"
              >
                <img src={DownlaodAppStore} alt="Downlaod appStore" />
              </Link>
            </div>
          </div>
        </div>
      )}

      </header>
    </div>
  );
};

export default Header;
