import React from "react";
import { Link, NavLink } from "react-router-dom";
// import PrivacyPolicy from "../Pages/PrivacyPolicy";
import "./Footer.css";
import {
  FaFacebookF,
  FaXTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa6";


const Footer = () => {
  return (
    <footer>
          <div className="footer-row">
            <div>
            <NavLink to='PrivacyPolicy'>
              <p>
              Privacy Policy
              </p>
            </NavLink>
            </div>

            <div className="">
            <NavLink to='TermsCondition'>
              <p>
              Terms & Condition
              </p>
            </NavLink>
          </div>
          </div>

          {/* <p>
            All Rights Reserved Copyright © 2024 Scrap Cart
          </p> */}


        <div className="social-links">
        <Link to="https://www.facebook.com/scrapcartpk">
          <div className="iconBox">
            <FaFacebookF color="#282828" />
          </div>
        </Link>

        <Link to="https://www.instagram.com/scrapcartpk/">
          <div className="iconBox">
            <FaInstagram color="#282828" />
          </div>
        </Link>
      </div>
        <p>
            All Rights Reserved Copyright © 2024 Scrap Cart
          </p>

    </footer>

  );
};

export default Footer;
