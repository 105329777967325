import Slider2 from "../assets/scrap.jpg";
import Slider1 from "../assets/scrapCollector.jpg";
import Slider3 from "../assets/slider3.png";
import Slider4 from "../assets/slider5.jpg";
import Slider5 from "../assets/slider5.webp";
import "react-slideshow-image/dist/styles.css";
import React, { useState } from "react";
import { Slide, Fade } from "react-slideshow-image";
import { collection, addDoc } from "firebase/firestore";
import { db, serverTimestamp } from "../FirebaseConfig";

const SellScrap = ({ id }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // name: "",
    phone: "",
    address: "",
    // state: '',
    zip: "",
    // email: '',
    scrapType: "",
    city: "", // Added city field

    // pickupDate: '',
    // remarks: ''
  });
  const [error, setError] = useState("");

  const SliderImage = [
    { url: Slider1, caption: "First Slide" },
    { url: Slider2, caption: "Second Slide" },
    { url: Slider3, caption: "Third Slide" },
    { url: Slider4, caption: "Fourth Slide" },
    { url: Slider5, caption: "Fifth Slide" },
  ];

  // const handleNext = () => {
  //   if (step === 1) {
  //     if (!formData.name || !formData.phone) {
  //       setError('Please fill all fields!');
  //       return;
  //     }
  //   } else if (step === 2) {
  //     if (!formData.address || !formData.state || !formData.zip || !formData.email) {
  //       setError('Please fill all fields!');
  //       return;
  //     }
  //   }
  //   setError('');
  //   setStep(step + 1);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (
      !formData.scrapType ||
      !formData.address ||
      !formData.zip ||
      !formData.phone ||
      !formData.city
    ) {
      setError("Please fill all fields!");
      return;
    }

    setError("");

    try {
      await addDoc(collection(db, "pickupRequests"), {
        phone: formData.phone,
        // phone: formData.phone,
        address: formData.address,
        // state: formData.state,
        zip: formData.zip,
        city: formData.city,
        // email: formData.email,
        scrapType: formData.scrapType,
        // pickupDate: formData.pickupDate,
        // remarks: formData.remarks,
        createdAt: serverTimestamp(),
      });
      console.log(formData);

      setFormData({
        phone: "",
        // phone: '',
        address: "",
        // state: '',
        zip: "",
        // email: '',
        scrapType: "",
        city: "",
        // pickupDate: '',
        // remarks: ''
      });
      // setStep(1);
    } catch (error) {
      console.error("Error submitting form:", error); // Debugging line
      setError(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  // const handleBack = () => {
  //   if (step > 1) {
  //     setStep(step - 1);
  //   }
  // };

  return (
    <div id={id}>
      {/* <div className="slider-container">
        <Fade duration={5600} transitionDuration={400}>
          {SliderImage.map((item, index) => (
            <div className="inner-slider" key={index}>
              <img src={item.url} alt={item.caption} />
            </div>
          ))}
        </Fade>
      </div> */}

      <div className="section-container">
        <div className="sellScrapContainer">
          <div className="onlineScrapSeller">
            <div className="row">
              <div className="sellScrapContent">
                <h1 className="sellHeading">Schedule Pick-Up</h1>
                <p className="sellScrapPara">Hassle Free doorstep pickup.</p>
                <p className="sellScrapPara">
                  We Take All Kind of Scrap Paper, Plastic, Metal, E-Waste, Old
                  Furniture, etc.
                </p>
              </div>

              {/* <div className="contactForSell">
                <div className="multi-step-form">
                  <div className="progress-indicator">
                    <div className={`dot ${step >= 1 ? "active" : ""}`}>1</div>
                    <div className={`line ${step >= 2 ? "active" : ""}`}></div>
                    <div className={`dot ${step >= 2 ? "active" : ""}`}>2</div>
                    <div className={`line ${step === 3 ? "active" : ""}`}></div>
                    <div className={`dot ${step === 3 ? "active" : ""}`}>3</div>
                  </div> */}

              <div className="sellScrap">
                {/* {step === 1 && (
                      <> */}

                <div className="inp-row">
                  <div className="phone-inp">
                    <label>
                      Phone no <span>*</span>
                    </label>
                    <div className="phone-input-container">
                      <span className="country-code">+92</span>
                      <input
                        type="text"
                        required
                        name="phone"
                        className="phone-input"
                        placeholder="32359145322"
                        value={formData.phone}
                        onChange={handleChange}
                        maxLength="10"
                      />
                    </div>
                  </div>

                  {/* <div className="sell-inp">
                          <label>Phone No <span>*</span></label>
                          <input
                            type="text"
                            placeholder="WhatsApp no / or Mobile no"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div> */}
                  <div className="sell-inp">
                    <label>
                      Scrap Type <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Scrap Type"
                      name="scrapType"
                      value={formData.scrapType}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="inp-row">
                  <div className="sell-inp">
                    <label>
                      City <span>*</span>
                    </label>
                    <select
                      name="city"
                      className="city-dropdown"
                      value={formData.city}
                      onChange={handleChange}
                    >
                      <option value="Karachi">Karachi</option>
                    </select>
                  </div>

                  <div className="sell-inp">
                    <label>
                      Zip Code <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Zip Code"
                      name="zip"
                      value={formData.zip}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {error && <p className="error">{error}</p>}

                {/* <div className="sell-inp">
                    <label>
                      City <span>*</span>
                    </label>
                    <select name="city"className="city-dropdown" value={formData.city}  onChange={handleChange}>
                      <option value="" disabled>Select City</option>
                      <option value='Karachi'>Karachi</option>
                      </select>
                  </div> */}

                <div className="sell-inp">
                  <label>
                    Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>

                <button onClick={handleSubmit} className="section-button">
                  SUBMIT
                </button>
                {/* </>
                    )} */}

                {/* {step === 2 && (
                      <>
                        <div className="inp-row">
                          <div className="sell-inp">
                            <label>Address <span>*</span></label>
                            <input
                              type="text"
                              placeholder="Enter Your Address"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="sell-inp">
                            <label>State <span>*</span></label>
                            <input
                              type="text"
                              placeholder="Enter Your State"
                              name="state"
                              value={formData.state}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="sell-inp">
                            <label>Zip Code <span>*</span></label>
                            <input
                              type="text"
                              placeholder="Enter Your Zip Code"
                              name="zip"
                              value={formData.zip}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="sell-inp">
                            <label>Email <span>*</span></label>
                            <input
                              type="email"
                              placeholder="Enter Your Email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>

                          {error && <p className="error">{error}</p>}
                          <div className="button-group">
                            <button onClick={handleBack} className="section-button">Previous</button>
                            <button onClick={handleNext} className="section-button">Next</button>
                          </div>
                        </div>
                      </>
                    )}

                    {step === 3 && (
                      <>
                        <div className="inp-row">
                          <div className="sell-inp">
                            <label>Scrap Type <span>*</span></label>
                            <input
                              type="text"
                              placeholder="Enter Scrap Type"
                              name="scrapType"
                              value={formData.scrapType}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="sell-inp">
                            <label>Pickup Date <span>*</span></label>
                            <input
                              type="date"
                              name="pickupDate"
                              value={formData.pickupDate}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="sell-inp">
                          <label>Remarks</label>
                          <input
                            type="text"
                            placeholder="Enter Remarks"
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                          />
                        </div>

                        {error && <p className="error">{error}</p>}
                        <div className="button-group">
                          <button onClick={handleBack} className="section-button">Previous</button>
                          <button className="section-button" onClick={handleSubmit}>Submit</button>
                        </div>
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellScrap;
