import React from 'react'
import "./Error.css"
import { Link, NavLink } from 'react-router-dom'
const Error = () => {
  return (
    <div className='error-page'>
    <div className='error-content'>
      <h1>404 - Page Not Found</h1>
      <p>Oops! It seems like the page you're looking for doesn't exist.</p>
      <NavLink to="/" className="section-button">
        go Back
      </NavLink>
    </div>
    </div>
  )
}

export default Error
