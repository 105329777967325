import React from "react";
import MarketReports from "./Pages/MarketReports";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BlogContent from "./components/BlogContent";
import AddReports from "./Pages/AddReports";
import AddBlogScreen from "./Pages/AddBlogScreen";
import Error from "./components/Error";
import PrivacyPolicy from './Pages/PrivacyPolicy'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TermsCondition from "./Pages/TermsCondition";

const App = () => {
  return (
    <BrowserRouter>
      <div className="main-container-fluid">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/marketReports" element={<MarketReports />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/BlogContent/:id" element={<BlogContent />} />
          <Route path="/addBlog" element={<AddBlogScreen />} />
          <Route path="/addReport" element={<AddReports />} />
          <Route path="*" element={<Error />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
          <Route path="termsCondition" element={<TermsCondition/>}/>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
