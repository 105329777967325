import React from "react";
import Intro from "../components/Intro";
import WhatWeSolve from "../components/WhatWeSolve";
import ThirdSection from "../components/ThirdSection";
import AddReportsForm from "./AddReports";
import Plans from "../components/Plans";
import HowItWorks from "../components/HowItWorks";
import "./Main.css";
import Contact from "../components/Contact"
import SellScrap from "../components/SellScrap";
import WhyScrap from "../components/WhyScrap";
import BookRequest from "../components/BookRequest";
import HowItWork from "../components/HowItWork";
import OurServices from "../components/OurServices";
import OurEnvironment from "../components/EmpackEnvironment";
import AfterCollection from "../components/AfterCollection";
import CustomerCount from "../components/CustomerCount";
import ServeServiceBrand from "../components/ServeServiceBrand";
import Accordion from "../components/Accordion";
import ScrapService from "../components/ScrapService";
import Testimonial from "../components/Testimonial";
import DownloadOurApp from "../components/DownloadOurApp";
import WasteServices from "../components/WasteServices";
import Scrapdeal from "../components/Scrapdeal";
import ZeroWaste from "../components/ZeroWaste";
import ScrapPickupApp from "../components/ScrapPickupApp";
import Banner from '../components/Banner'
const Home = () => {
  return <>
  <Banner id='banner'/>
  <SellScrap id="sell-scrap"/>
  {/* <WasteServices/> */}
  {/* <Scrapdeal/> */}
  <WhyScrap id="why-scrapCart"/>
  <DownloadOurApp id="download-our-app"/>
  {/* <ScrapPickupApp/> */}
  <ZeroWaste id="after-collection"/>
  {/* <HowItWorks id="how-it-work"/> */}
  {/* <OurServices id="our-services"/> */}
  {/* <AfterCollection id="after-collection"/> */}
  {/* <BookRequest id="Book-a-req"/> */}
  {/* <HowItWork id="how-it-works"/> */}
  {/* <OurEnvironment id="our-enviroment"/> */}
  {/* <ScrapService/> */}
  <Testimonial id="reviews"/>
  {/* <CustomerCount id="Customer-count"/> */}
  {/* <Accordion /> */}
  {/* <ServeServiceBrand id="serve-service-brand"/> */}
  {/* <Intro id="intro"/>
  <WhatWeSolve id="what-we-solve"/> */}
  {/* <ThirdSection id="products"/>
  <Plans id="plans"/>
  <Contact id="contact-us"/> */}
  </>
};

export default Home;
