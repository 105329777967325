import React, { useState } from "react";
import PasswordModal from "../components/PasswordModal";
import { db, serverTimestamp } from "../FirebaseConfig";
import { addDoc, collection } from "firebase/firestore";

const AddReports = () => {
  const [titles, setTitles] = useState([""]);
  const [descriptions, setDescriptions] = useState([""]);
  const [passwordModalOpen, setPasswordModalOpen] = useState(true);
  const [language, setLanguage] = useState("english"); // Default language is English

  const handlePasswordSuccess = () => {
    setPasswordModalOpen(false);
  };

  const handleTitleChange = (index, value) => {
    setTitles((titles) => {
      const newTitles = [...titles];
      newTitles[index] = value;
      return newTitles;
    });
  };

  const handleDescriptionChange = (index, value) => {
    setDescriptions((descriptions) => {
      const newDescriptions = [...descriptions];
      newDescriptions[index] = value;
      return newDescriptions;
    });
  };

  const handleAddInput = () => {
    if (titles.some((title) => title === "") || descriptions.some((description) => description === "")) {
      alert("Please enter data in both fields.");
      return;
    }
    setTitles([...titles, ""]);
    setDescriptions([...descriptions, ""]);
  };

  const addReport = async () => {
    try {
      const reportData = titles.reduce((data, title, index) => {
        data[`title${index + 1}`] = title.trim();
        data[`description${index + 1}`] = descriptions[index].trim();
        return data;
      }, { createdAt: serverTimestamp() });
      await addDoc(collection(db, "marketReports"), reportData);

      console.log("Report added successfully");
      setTitles([""]);
      setDescriptions([""]);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const isDisabled = titles.some((title) => title === "") || descriptions.some((description) => description === "");

  return (
    <div className="add-blog-container">
      {passwordModalOpen ? (
        <PasswordModal onSuccess={handlePasswordSuccess} />
      ) : (
        <>
          <h2 className="add-blog-heading">{language === "english" ? "Add New Report" : "نیا رپورٹ شامل کریں"}</h2>
          
          {titles.map((title, index) => (
            <div key={index} className="input-group">
              <input
                type="text"
                className="blog-input"
                placeholder={`${language === "english" ? "Report Title" : "رپورٹ کا عنوان"} ${index + 1}`}
                value={title}
                onChange={(event) => handleTitleChange(index, event.target.value)}
              />
              <textarea
                className="blog-input blog-content"
                placeholder={`${language === "english" ? "Report Description" : "رپورٹ کی وضاحت"} ${index + 1}`}
                value={descriptions[index]}
                onChange={(event) => handleDescriptionChange(index, event.target.value)}
              />
            </div>
          ))}
          
          <button className="language-btn" onClick={() => setLanguage(language === "english" ? "urdu" : "english")}>
            {language === "english" ? "Switch to Urdu" : "Switch to English"}
          </button>

          <button className="add-blog-btn add-report-btn" onClick={handleAddInput}>+</button>
          <button className="add-blog-btn add-report-btn" onClick={addReport} disabled={isDisabled}>
            {language === "english" ? "Add Report" : "رپورٹ شامل کریں"}
          </button>
        </>
      )}
    </div>
  );
};

export default AddReports;
