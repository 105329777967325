import React from 'react'
import "./privacyPolicy.css"

const PrivacyPolicy = () => {
        return (
            <div className="container">
            <div className="header">
              <h1>Privacy Policy</h1>
              <p>Last Updated August 7, 2024</p>
            </div>
      
            <div className="section">
              <h2>1. Introduction</h2>
              <p>
                Welcome to ScrapCart. This Privacy Policy explains how we handle the information that is required to use our mobile application ("App"). We are committed to ensuring that your information is handled securely and transparently.
              </p>
            </div>
      
            <div className="section">
              <h2>2. Information Required to Use the App</h2>
              <p>To function correctly, the App may request access to:</p>
              <ul>
                <li>Location: Required to help you find and interact with scrap services near you.</li>
                <li>Photos and Camera: Required to upload images when posting ads.</li>
              </ul>
              <p>We do not actively collect or store this information; it is only used temporarily to provide the functionality requested by you.</p>
            </div>
      
            <div className="section">
              <h2>3. Use of Permissions</h2>
              <p>
                Location Information: Used solely to improve your experience by providing location-based services. This data is not stored or shared with third parties.
              </p>
              <p>
                Photos and Camera Access: Used to enable you to upload or capture images within the App. Images are not stored by us beyond the immediate use for your ad postings.
              </p>
            </div>
      
            <div className="section">
              <h2>4. Data Security</h2>
              <p>
                We use standard security measures to ensure that the data accessed is handled securely. Since we do not store this data, we rely on the inherent security of your device and operating system.
              </p>
            </div>
      
            <div className="section">
              <h2>5. Your Choices</h2>
              <p>
                You can manage permissions through your device settings. If you choose not to grant these permissions, certain features of the App may not be available to you.
              </p>
            </div>
      
            <div className="section">
              <h2>6. Changes to This Policy</h2>
              <p>
                We may update this Privacy Policy occasionally. The revised policy will be effective from the date of publication.
              </p>
            </div>
      
            <div className="section">
              <h2>7. Contact Us</h2>
              <p>
                For any questions about this Privacy Policy, please contact us at:
              </p>
              <p>Email: <a href="mailto:ahmedr.0331@gmail.com">ahmedr.0331@gmail.com</a></p>
            </div>
          </div>
        );
      };
      

export default PrivacyPolicy