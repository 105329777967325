import React from 'react';
import './Testimonial.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoStarSharp } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';


const NextArrow = (props ) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', color: 'black', fontSize: '15px'}}
      onClick={onClick}
    >
      <FaChevronRight />
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', color: 'black', fontSize: '15px'}}
      onClick={onClick}
    >
      <FaChevronLeft />
    </div>
  );
}

const Testimonial = ({id}) => {
  const testimonialData = [
    {
      name: "Ahmed",
      review: "I used Scrap cart to sell scrap online and the process was smooth and efficient. Highly recommend!"
    },
    {
      name: "Sara",
      review: "Scrap cart provided a great service for selling my old furniture. The pickup was on time and the payment was quick."
    },
    {
      name: "Shahin",
      review: "Excellent platform! I managed to get rid of my e-waste easily and got a good price for it."
    },
    {
      name: "Aisha",
      review: "Scrap cart's customer service is top-notch. They guided me through the entire process and made it hassle-free."
    },
    {
      name: "Armaan",
      review: "I was able to clear out my garage using Scrap cart. The entire experience was seamless and convenient."
    },
    {
      name: "Fatima",
      review: "Great service! I sold my scrap metal and received payment promptly. Will definitely use Scrap cart again."
    },
    {
      name: "Bilal",
      review: "Scrap cart is a game-changer for selling scrap. The website is user-friendly and the pickup service is reliable."
    },
    {
      name: "Zara",
      review: "I found Scrap cart to be very professional. They handled my scrap sale efficiently and offered a fair price."
    },
    {
      name: "Kamran",
      review: "Using Scrap cart was a fantastic experience. The process was straightforward and the staff was very helpful."
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='testimonial-container' id={id}>
      <h1 className='section-title'>What our Customers Say</h1>
      <Slider {...settings}>
        {testimonialData.map((item, index) => (
          <div className='testimonial-box' key={index}>
            <div className='qoute-box'>
            <img src="https://cdn-icons-png.flaticon.com/512/690/690354.png" alt="" width='20px'/>
            </div>
            <h2 className='client-name'>{item.name}</h2>
            <p>{item.review}</p>
            <div className="stars-row">
                <IoStarSharp />
                <IoStarSharp />
                <IoStarSharp />
                <IoStarSharp />
                <IoStarSharp />
                </div>
            {/* <img src="https://cdn-icons-png.freepik.com/512/157/157248.png" alt="" width='20px'/> */}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Testimonial;
