import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBfG89mVj7MMYcbbxFCegr1_pP1_5reohg",
  authDomain: "scrapp-ee304.firebaseapp.com",
  projectId: "scrapp-ee304",
  storageBucket: "scrapp-ee304.appspot.com",
  messagingSenderId: "549058211742",
  appId: "1:549058211742:web:e0758007110b9fac90308f"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

setPersistence(auth, browserSessionPersistence);

export { auth, db, serverTimestamp, storage };
