import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../FirebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const BlogContent = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const docRef = doc(db, 'blogs', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setBlog({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        setError('Error fetching document');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='blogContent'>
      <img src={blog.imageUrl} alt="" />
      <p>{blog.createdAt ? blog.createdAt.toDate().toLocaleDateString() : 'Unknown Date'} {blog.author}</p>
      <h1>{blog.title}</h1>
      <p>{blog.blogContent}</p>
    </div>
  );
};

export default BlogContent;
