import React from 'react'
import './zeroWaste.css'
import Image1 from "../assets/1.png"
import Image2 from "../assets/2.png"
import Image3 from "../assets/3.png"
import Image4 from "../assets/4.png"

const ZeroWaste = ({id}) => {
  return (
    <div id={id} className='proces-container'>
        {/* <div className="bg-banner"></div>
        <div className="zero-waste-content"> */}

            {/* <div className="text">
            <h1>After Collection</h1>
            <p>With our zero waste management services we help your society to turn zero waste by incorporating zero waste practices within its residents & stakeholders.</p>
            </div>

            <div className="solu-services-box">
                <div className="service">
                    <div className="service-heading">
                        <h3>Why</h3>
                        <div className='count'>1</div>
                    </div>
                    <p>To promote the circular flow of materials to reduce the need for landfill space.</p>
                </div>

                <div className="service">
                    <div className="service-heading">
                        <h3>Benefits</h3>
                        <div className='count'>2</div>
                    </div>
                    <p>Establishing circular economy benefits in reducing climate impact, conserving resources and minimizing pollution.</p>
                </div>


                <div className="service">
                    <div className="service-heading">
                        <h3>Our Solution</h3>
                        <div className='count'>3</div>
                    </div>
                    <p>Our services help you prevent wasteful practices by reducing, reusing, and recycling waste to become a zero waste society.</p>
                </div>

            
            </div> */}


        {/* </div> */}
{/* <div className="shape1" id={id}></div> */}
<h1 className='section-title'>After Collection</h1>

<div className="proces-boxes">
    <div className="inner">
        <div className="inner-heading">
            <h3>♻️ Sustainable Reintegration</h3>
            <div className='count'>3</div>
        </div>
        <p>Your collected scrap is reintegrated into the production cycle, promoting a circular economy and reducing the need for virgin materials.</p>
    </div>
    
    <div className="inner">
        <div className="inner-heading">
            <h3>🔄 Material Processing</h3>
            <div className='count'>1</div>
        </div>
        <p>After collection, the scrap is sorted and processed for recycling, ensuring maximum recovery of reusable materials while minimizing waste.</p>
    </div>

    <div className="inner">
        <div className="inner-heading">
            <h3>🌱 Environmental Impact</h3>
            <div className='count'>2</div>
        </div>
        <p>By recycling scrap materials, we reduce pollution, conserve natural resources, and limit the carbon footprint, contributing to a cleaner environment.</p>
    </div>
</div>

    </div>
  )
}

export default ZeroWaste