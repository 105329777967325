import React from 'react'
import "./privacyPolicy.css"


const TermsCondition = () => {
  return (
    <div className="container">
    <div className="header">
      <h1>Terms & Conditions</h1>
      <p>Last Updated August 7, 2024</p>
    </div>

    <div className="section">
      <h2>1. Acceptance of Terms</h2>
      <p>
        By using ScrapCart, you agree to these Terms & Conditions. If you do not agree, please do not use the App.
      </p>
    </div>

    <div className="section">
      <h2>2. Use of the App</h2>
      <p>Eligibility: You must be at least 18 years old to use the App.</p>
      <p>Account: You may need to create an account to access certain features.</p>
    </div>

    <div className="section">
      <h2>3. Permissions</h2>
      <p>To use certain features of the App, you may need to grant access to:</p>
      <ul>
        <li>Location Services: For location-based functionality.</li>
        <li>Photos and Camera: For uploading images.</li>
      </ul>
      <p>These permissions are only used temporarily and are not stored by us.</p>
    </div>

    <div className="section">
      <h2>4. Intellectual Property</h2>
      <p>
        All content in the App, including logos and text, is owned by ScrapCart or its licensors and is protected by copyright and trademark laws.
      </p>
    </div>

    <div className="section">
      <h2>5. Limitation of Liability</h2>
      <p>
        ScrapCart is not liable for any indirect or consequential damages resulting from your use of the App.
      </p>
    </div>

    <div className="section">
      <h2>6. Termination</h2>
      <p>We may suspend or terminate your access if you violate these Terms.</p>
    </div>

    <div className="section">
      <h2>7. Governing Law</h2>
      <p>These Terms are governed by the laws of [Your Country/State].</p>
    </div>

    <div className="section">
      <h2>8. Changes to Terms</h2>
      <p>We may update these Terms from time to time. Changes will be effective upon publication.</p>
    </div>

    <div className="section">
      <h2>9. Contact Us</h2>
      <p>
        For any questions, please contact us at:
      </p>
      <p>Email: <a href="mailto:ahmedr.0331@gmail.com">ahmedr.0331@gmail.com</a></p>
    </div>
  </div>
  )
}

export default TermsCondition
